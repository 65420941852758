@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.bg-primary{
  background-color: #ffffff;
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #5e73d4 0.89%,
    #455ba5 17.23%,
    #3568d4 42.04%,
    #4b5ec7 55.12%,
    #485dd1 71.54%,
    #1d1a45 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #5e5cda -43.27%,
    #444fac -21.24%,
    #3f59b1 12.19%,
    #394f8d 29.82%,
    #305996 51.94%,
    #1e1a46 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #1e1a46 91.61%
  );
}

.blue-border {
  border: 1px solid rgb(0, 132, 255);
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #1e1a46 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.navbar.fixed{
  transition: .3s ease-in-out;
}

.bg-black-img{
  background-image: url("../src/assets/a9d44dc41189ea46b8eebd73ec63384c.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

#home{
  background: linear-gradient(
    157.81deg,
    #5e5cda -43.27%,
    #444fac -21.24%,
  );
}
#header{
  background-image: url("../src/assets/94f0458aa02926000933863ac47fbf13.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
#heroserv{
  background-repeat: no-repeat;
  background-size: cover;
}


@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

a {
  text-decoration: none;
  color: rgb(97, 97, 97);
}

li {
  list-style: none;
}

.btns {
  padding: 10px 20px;
  margin-right: 6px;
  background: transparent;
  border: none;
  border: 0.6px solid #ccc;
  border-radius: 5px;
  color: #323232;
  cursor: pointer;
}

.btns1 {
  padding: 10px 20px;
  margin-right: 6px;
  background: rgb(23, 173, 3);
  border: none;
  border: 0.6px solid #ccc;
  border-radius: 5px;
  color: #f8f8f8;
  cursor: pointer;
}


/* Products-page */
.background {
  background: #f6f9fc;
  padding: 50px 0;
}
.container {
  max-width: 95%;
  margin: auto;
}
.d_flex {
  display: flex;
  justify-content: space-between;
}
.c_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.f_flex {
  display: flex;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.RText {
  text-align-last: right;
}
input,
button {
  border: none;
  outline: none;
  background-color: none;
}
.row {
  width: 50%;
}
.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  margin-left: 20px;
  text-align: center;
  border-radius: 50%;
}
.boxShadow {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}


/* features*/
.wrapper {
  text-align: center;
}
.wrapper .img {
margin: auto;
width: 70px;
height: 70px;
line-height: 70px;
margin-bottom: 20px;
}
.wrapper .img i {
font-size: 25px;
}
.wrapper .product {
padding: 30px;
}
.wrapper .product h3 {
font-weight: 500;
}
.wrapper p {
font-size: 15px;
margin-top: 20px;
color: grey;
}


/*---------category----------*/
.contentWidth {
  width: 77%;
}
.category {
  box-shadow: rgba(57, 63, 73, 0.1) 0px 4px 16px;
  background-color: #fff;
  width: 23%;
  height: 100%;
  padding: 15px 0;
  border-radius: 5px;
  margin-top: 5px;
}
.category img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  object-fit: contain;
}
.category span {
  margin: 10px 20px;
  text-transform: capitalize;
}
.category .box {
  transition: 0.5s;
  padding: 0 20px;
}
.category .box:hover {
  background-color: #256aeb;
  cursor: pointer;
}

/*---------category----------*/
.btn-primary {
  background: #256aeb;
  padding: 13px 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}
.top {
  margin-top: 80px;
}
.mtop {
  margin-top: 40px;
}
.heading i {
  color: #256aeb;
  margin: 10px;
}
.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #256aeb;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}
/*---------product-----------*/
.product {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}
.product h3 {
  font-weight: 400;
  font-size: 17px;
}
.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}
.product .price {
  display: flex;
  justify-content: space-between;
  color: #256aeb;
}
.price button {
  background: none;
  color: #256aeb;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
.price button:hover {
  cursor: pointer;
  background-color: #256aeb;
  color: #fff;
}
.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}
.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}
.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}
.product:hover .product-like {
  opacity: 1;
}
/*---------product-----------*/
.products {
  background: #f6f9fc;
}

/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 60px;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -20px;
}
.control-btn .next {
  right: -20px;
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  background: #256aeb;
  color: white;
  cursor: pointer;
}
/*----- control btn -------*/
/*----- heading -------*/
.heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: grey;
}
/*----- heading -------*/
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

/* categoriesbar */
l{
  list-style: none;
}

a {
  text-decoration: none;
}

.menu-trigger img{
  position: absolute;
  top: 120px;
  left: 40px;
  height: 40px;
  width: 100px;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-menu{
  position: absolute;
  top: 170px;
  left: 20px;
  background-color: #fff;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  width: 200px;
}

.dropdown-menu::before{
  content: '';
  position: absolute;
  top: -5px;
  left: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}


h3{
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  font-size: 18px;
  color: var(--primary-text-color);
  line-height: 1.2rem;
}

h3 span{
  font-size: 14px;
  color: var(--secondary-text-color);
  font-weight: 400;
}

.dropdown-menu ul li{
  padding: 10px  0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a{
  color: rgb(9, 104, 212);
  cursor: pointer;
}

.dropdown-menu ul li:hover img{
  opacity: 1;
  cursor: pointer;
}

.dropdownItem{
  display: flex;
  margin: 10px auto;
}

.dropdownItem img{
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a{
  max-width: 100px;
  margin-left: 10px;
  transition: var(--speed);
}


/* contact-page */
Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}

.hash-span {
  margin-top: -100px;
  padding-bottom: 100px;
  display: block;
}

.black-gradient {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.violet-gradient {
  background: #804dee;
  background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
  background: -webkit-linear-gradient(
    -90deg,
    #804dee 0%,
    rgba(60, 51, 80, 0) 100%
  );
}

.green-pink-gradient {
  background: "#00cea8";
  background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
  background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
}

.orange-text-gradient {
  background: #f12711; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #f12711,
    #f5af19
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #f12711,
    #f5af19
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green-text-gradient {
  background: #11998e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #11998e,
    #38ef7d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #11998e,
    #38ef7d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue-text-gradient {
  /* background: -webkit-linear-gradient(#eee, #333); */
  background: #56ccf2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #2f80ed,
    #56ccf2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #2f80ed,
    #56ccf2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
  background: #ec008c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #ec008c,
    #fc6767
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #ec008c,
    #fc6767
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* canvas- styles */
.canvas-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}