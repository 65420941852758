@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


.product-list {
  align-items: flex-start;
  border: 1px blue;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.product-list .div {
  height: 322px;
  margin-right: 10px;
  position: relative;
  width: 1731px;
}

.product-list .medical-gases {
  align-items: flex-start;
  display: inline-flex;
  gap: 54px;
  justify-content: flex-end;
  left: 50px;
  overflow-x: scroll;
  position: absolute;
  top: 64px;
}

.product-list .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.product-list .img {
  height: 180px;
  object-fit: cover;
  position: relative;
  width: 188px;
}

.product-list .text-wrapper {
  color: #6a6a6a;
  font-family: "Inter-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 188px;
}

.product-list .line {
  height: 1px;
  left: 137px;
  object-fit: cover;
  position: absolute;
  top: 322px;
  width: 1303px;
}

.product-list .text-wrapper-2 {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 34px;
  font-weight: 500;
  height: 44px;
  left: 137px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 283px;
}

.product-list .text-wrapper-3 {
  color: #000000;
  font-family: "Inter-Medium", Helvetica;
  font-size: 34px;
  font-weight: 500;
  left: 137px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 283px;
}
